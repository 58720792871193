import React from "react";
import { graphql, Link } from "gatsby";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Helmet } from "react-helmet";

import { BlogQuery, SitePageContext } from "../../graphql-types";
import { Footer, Header, HomeHero } from "../components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1200,
      margin: "75px auto 75px auto",
    },
    post_list: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      columnGap: 64,
      rowGap: 75,
      marginTop: 50,
    },
    post: {
      color: "inherit",
      textDecoration: "none",

      "& img": {
        width: "100%",
        height: 360,
        borderRadius: 10,
      },
      "& > *": {
        marginBlockStart: "8px",
        marginBlockEnd: 0,
      },

      "& h2": {
        fontSize: "1.5rem",
      },
    },
    post_author: {
      color: theme.palette.primary.main,
      fontSize: "1rem",

      "& span": {
        margin: "0 4px",
      },
    },
    actions: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(8),
    },
    button: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 100,
      padding: theme.spacing(2),
      boxShadow: "0px 3px 6px #00000029",
    },
  })
);

interface BlogPostItemProps {
  post: any;
}

const BlogPostItem: React.FC<BlogPostItemProps> = (
  props: BlogPostItemProps
) => {
  const { post } = props;

  const classes = useStyles();

  return (
    <Link to={`/blog/${post.slug}`} className={classes.post}>
      <img
        src={post.featuredImage.node.localFile.childImageSharp.original.src}
      />
      <p className={classes.post_author}>
        {post.author.node.name} <span>&#8226;</span> {post.date}
      </p>
      <h2>{post.title}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: post.excerpt.replace(/<a.*?<\/a>/i, ""),
        }}
      ></div>
    </Link>
  );
};

interface Props {
  data: BlogQuery;
  pageContext: SitePageContext;
}

const Blog: React.FC<Props> = ({ data, pageContext }: Props) => {
  const { allWpPost, site, strapiBlogContent } = data;

  const classes = useStyles();

  return (
    <div>
      <Helmet title="Blog">
        <meta name="description" content="FlourishZone Blog" />
        <meta
          name="image"
          content={strapiBlogContent.hero_image.childImageSharp.original.src}
        />

        {/* Open Graph */}
        <meta property="og:type" content="blog" />
        <meta property="og:title" content="Blog" />
        <meta property="og:description" content="FlourishZone Blog" />
        <meta property="og:url" content={`${site.siteMetadata.url}/blog`} />
        <meta
          property="og:image"
          content={strapiBlogContent.hero_image.childImageSharp.original.src}
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Blog" />
        <meta name="twitter:description" content="Blog" />
        <meta name="twitter:url" content={`${site.siteMetadata.url}/blog`} />
        <meta
          name="twitter:image"
          content={strapiBlogContent.hero_image.childImageSharp.original.src}
        />
      </Helmet>

      <Header />

      <HomeHero
        img={strapiBlogContent.hero_image.childImageSharp.original.src}
        title={strapiBlogContent.title}
        subtitle={strapiBlogContent.subtitle}
        hideSignUp
        hideTabs
      />

      <div className={classes.container}>
        <div className={classes.post_list}>
          {allWpPost.nodes.map(post => (
            <BlogPostItem key={post.id} post={post} />
          ))}
        </div>

        <div className={classes.actions}>
          <Button
            style={{
              visibility: !pageContext.previousPagePath ? "hidden" : "visible",
            }}
            className={classes.button}
            component={Link}
            to={pageContext.previousPagePath}
          >
            NEWER ARTICLES
          </Button>

          <Button
            style={{
              visibility: !pageContext.nextPagePath ? "hidden" : "visible",
            }}
            className={classes.button}
            component={Link}
            to={pageContext.nextPagePath}
          >
            OLDER ARTICLES
          </Button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export const query = graphql`
  query Blog($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        url
      }
    }
    allWpPost(sort: { fields: date, order: DESC }, skip: $skip, limit: $limit) {
      nodes {
        id
        title
        uri
        excerpt
        date(formatString: "d MMMM Y")
        slug
        featuredImage {
          node {
            localFile {
              url
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
        author {
          node {
            name
          }
        }
      }
    }
    strapiBlogContent {
      title
      subtitle
      hero_image {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
`;

export default Blog;
